import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Templatemodal from "../../modals/templatemodal";
import getCampaignList from "../../utils/compaignList/getCampaignList";
import { useCompaign } from "../../context/CompaignProvider";
import { Pagination, Tooltip } from "antd";
import { usePlans } from "../../context/PlansProvider";
import { IoMdLock } from "react-icons/io";

const Dashboardmain = () => {
  const navigate = useNavigate();
  const campaign = sessionStorage.getItem("template");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { campaigndata, setCampaignData } = useCompaign();
  const { activePlan } = usePlans();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  // Pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleData = campaigndata.slice(startIndex, endIndex);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleRowClick = (campaign_name) => {
    navigate(`/dashboard/campaign-details/${campaign_name}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const formattedTimeUpperCase = formattedTime.replace(/am|pm/i, match => match.toUpperCase());

    return `${formattedDate} at ${formattedTimeUpperCase}`;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const getCompaignsData = async () => {
      const response = await getCampaignList();

      if (response.success) {
        setCampaignData(response.data.reverse());
        sessionStorage.setItem("compaignList", JSON.stringify(response.data));
      }
    };
    getCompaignsData();
  }, [setCampaignData]);

  useEffect(() => {
    if (campaign) {
      const campaignArray = JSON.parse(campaign);
      const isEmpty = campaignArray.some(
        (item) =>
          !item.template_body ||
          item.template_body === "<p><br></p>" ||
          item.template_body === ""
      );

      if (!isEmpty) {
        navigate("/dashboard/startcampaign");
      }
    }
  }, [navigate, campaign]);

  const campaignArray = campaign ? JSON.parse(campaign) : null;
  const isEmptyCampaign = campaignArray?.some(
    (item) =>
      !item.template_body ||
      item.template_body === "<p><br></p>" ||
      item.template_body === ""
  );

  if (!campaignArray || isEmptyCampaign) {
    return (
      <>
        <div className="dashboard-main">
          <h4 className="elarge">Campaigns</h4>

          <div className="campagins-inner">
            <div className="camp-btns h-center">
              <Tooltip title="AI template generation">
                <button
                  className="v-center h-center gap-8 text-black generate-btn"
                  onClick={() => {
                    navigate("/dashboard/createcampaign");
                  }}
                >
                  Generate with AI
                  <img src="../../../assets/icons/glitter.svg" alt="" />
                </button>
              </Tooltip>

              <Tooltip title={!activePlan?.subscription_name ? "Subscribe a plan first" : "Use saved templates"} >
                <button
                  className={`text-black button-white template-btn ${activePlan?.subscription_name ? '' : 'bg-purple'}`}
                  onClick={showModal}
                  disabled={activePlan?.subscription_name ? false : true}
                >
                  {!activePlan?.subscription_name && <IoMdLock size={24} />} Templates
                </button>
              </Tooltip>
            </div>

            <div className="active-compaigns bg-white mt-4 rounded-4">
              {activePlan?.subscription_name ? (
                <div className="w-full">
                  <table className="camp-table">
                    <thead>
                      <tr className="camp-email-thead ">
                        <th>Campaign Name</th>
                        <th>Templates name</th>
                        <th>Total Contacts</th>
                        <th>Date of creation</th>
                      </tr>
                    </thead>

                    <tbody>
                      {visibleData?.map((data, index) => (
                        <tr
                          key={index}
                          className="send-email-tbody"
                          onClick={() => handleRowClick(data.campaign_name)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{data.campaign_name.length > 30 ? `${data.campaign_name.slice(0, 30)}...` : data.campaign_name}</td>
                          <td>
                            {data.template_data
                              .map((td) => td.template_name)
                              .join(", ").length > 30
                              ? `${data.template_data.map((td) => td.template_name).join(", ").slice(0, 30)}...`
                              : data.template_data.map((td) => td.template_name).join(", ")}
                          </td>

                          <td>{data["Total Contacts"]}</td>
                          <td>{formatDate(data["Sending Date"])}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {campaigndata.length < 1 && (
                    <div className="text-center text-black montserrat-family w-full p-4">
                      No campaigns to show here
                    </div>
                  )}

                  <div className="contacts-bottom-div d-flex space-between align-items-center gap-2 pt-4">
                    <label className="small">{`Showing ${campaigndata.length === 0 ? startIndex : startIndex + 1} to ${endIndex > campaigndata.length
                      ? campaigndata.length
                      : endIndex
                      } of ${campaigndata.length} results`}</label>

                    <Pagination
                      // className="camp-pagination"
                      defaultCurrent={1}
                      total={campaigndata.length}
                      pageSize={itemsPerPage}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              ) : (
                <div className="w-full d-flex v-center flex-column h-center">
                  <IoMdLock size={42} />
                  <div className="text-center campaign-noplan-text p-4">
                    Subscription is required for Campaign Table
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Templatemodal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </>
    );
  } else {
    return null;
  }
};

export default Dashboardmain;
