import React from "react";
import "./working-steps.scss";

const WorkingSteps = () => {
  const workingSteps = [
    {
      id: 1,
      title: "Sign up",
      description: "Connect your gmail account and set up your profile.",
      img: "../assets/images/steps/login.png",
    },
    {
      id: 2,
      title: "Create Campaign",
      description:
        "Input your personal preferences into our ai generator for tailored templates. Set clear objectives for what you want each campaign to achieve.",
      img: "../assets/images/steps/campaign.png",
    },
    {
      id: 3,
      title: "Personalize",
      description:
        "Use single brackets for simple data swaps and double brackets for dynamic, personalized content via Chat GPT 4.0.",
      img: "../assets/images/steps/personalize.png",
    },
    {
      id: 4,
      title: "Preview and Perfect",
      description:
        "Before any emails go out, swipe through each one to make any necessary adjustments.",
      img: "../assets/images/steps/preview.png",
    },
    {
      id: 5,
      title: "Track and Optimize",
      description:
        "Monitor real-time metrics through a comprehensive analytics dashboard. Adjust your strategies based on performance data to continually enhance engagement.",
      img: "../assets/images/steps/analytics.png",
    },
  ];

  return (
    <section className="working-steps">
      <h1> Getting {" "}
        <span>Started</span>
      </h1>

      <div className="steps-container">
        {workingSteps.map((step) => (
          <div key={step.id} className="step-item">
            {/* Content section */}
            <div className="content-box">
              <h2>{step.title}</h2>
              <p>{step.description}</p>
            </div>

            {/* Visual section */}
            <div className="img-box">
              <img src={step.img} alt={step.title} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WorkingSteps;
