import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { usePlans } from "../context/PlansProvider";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import StripePayment from "../utils/payment/StripePayment";
import { Modal, notification } from "antd";
import { useCompaign } from "../context/CompaignProvider";
import { IoCloseCircle } from "react-icons/io5";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Pricingcard = ({ item, annual }) => {
  const { activePlan } = usePlans();
  const [userPlan, setUserPlan] = useState({});
  const { profile } = useCompaign();
  const [opensModal, setOpensModal] = useState(false);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const handleCheckout = async (name) => {
    const stripe = await stripePromise;

    if (activePlan?.subscription_name) {
      setOpensModal(true);
    } else {
      const data = {
        plan_name: name === 'Free Trial' ? 'Free Trial' : `${name} ${annual ? "Yearly" : "Monthly"}`,
        // price: plan.price,
        // duration: annual ? "year" : "month",
        // email: sessionStorage.getItem("email"),
      };

      const response = await StripePayment(data);

      if (response.success) {
        const result = await stripe.redirectToCheckout({
          sessionId: response.session,
        });

        if (result.error) {
          console.error(result.error.message);
        } else {
          navigate("/settings?tab=subscription");
        }
      } else {
        let errorMessage = response.message;

        if (errorMessage.includes("already has an active subscription")) {
          errorMessage = "You already have an active subscription.";
        }
        api.open({
          message: errorMessage,
          icon: <IoCloseCircle className="false-icon" />,
        });
      }
    }
  };

  const handleOk = () => {
    setOpensModal(false);
    navigate("/settings?tab=subscription");
  };

  useEffect(() => {
    if (activePlan.subscription_name) {
      const info = activePlan.subscription_name.split(" ");
      setUserPlan({
        plan_name: info[0],
        duration: info[1] === "Monthly" ? "month" : "year",
      });
    }
  }, [activePlan.subscription_name]);

  return (
    <>
      <div
        key={item.id}
        className={`pricingcard-box ${item.name === "Pro" ? "pro" : ""}`}
      >
        <div className="card-top v-center space-between">
          <h4 className="text-darkpurple">{item.name}</h4>
          {!annual && <p className="text-darkpurple">Save $40</p>}
        </div>

        <div className="card-content">
          {item.bullets.map((e) => (
            <span key={e.id} className="v-center mt-16">
              <Icon
                color={e.enable ? "green" : "red"}
                icon={e.enable ? "entypo:check" : "ion:close"}
                width={16}
                height={16}
                className="active"
              />
              <label
                className={`large ${e.enable ? "text-darkpurple" : "text-lightpurple"
                  }`}
              >
                {e.bullet}
              </label>
            </span>
          ))}
        </div>

        <div className="card-bottom-content d-flex flex-column">
          <div className="d-flex justify-content-between h-center gap-2">
            <div className="amt-txt v-center">
              <span className="d-flex">
                <label className="large">$</label>
                <h4>{item.price}</h4>
              </span>
              <label className="large">{annual ? "/year" : "/month"}</label>
            </div>

            {/* old price */}
            {/* {annual && (
            <div className="amt-txt v-center old-price">
              <span className="d-flex ">
                <label className="large">$</label>
                <h4>{item.oldprice}</h4>
              </span>

              <label className="large">/year</label>
            </div>
          )} */}
          </div>

          <button
            className={`text-darkpurple ${userPlan.plan_name === item.name &&
              userPlan.duration === (annual ? "year" : "month")
              ? "btn-disable"
              : ""
              }`}
            onClick={() => {
              if (item.name === 'Base' && !annual && !profile.trial_used) {
                handleCheckout('Free Trial')
              } else {
                handleCheckout(item.name)
              }
            }}
          >
            {userPlan.plan_name === item.name &&
              userPlan.duration === (annual ? "year" : "month")
              ? "Subscribed"
              : userPlan.plan_name // Check if any plan is subscribed
                ? "Upgrade Plan"
                : (item.name === 'Base' && !annual && !profile.trial_used)
                  ? "Start 7 Days Free Trial"
                  : "Get Started"}
          </button>
        </div>
      </div>

      <Modal
        open={opensModal}
        onOk={handleOk}
        onCancel={() => setOpensModal(false)}
        className="credit-modal"
        centered={true}
      >
        <div className="modal-content">
          <div className="purple-header"></div>
          <div className="bottom-content v-center flex-column">
            <img
              src="../../assets/images/dashboardimages/creditImg.svg"
              alt="credits"
              className="w-auto h-auto"
            />
            <h4 className="elarge montserrat-family text-center">
              Want to Unsubscribed current plan?
            </h4>
            <p className="p-med text-beige segoe-family text-center">
              Already subscribed {activePlan.subscription_name} Plan
            </p>
            <div className="modal-buttons d-flex gap-16 mt-24">
              <button
                className="text-darkpurple segoe-family"
                onClick={() => setOpensModal(false)}
              >
                No
              </button>
              <button onClick={handleOk}>Yes</button>
            </div>
          </div>
        </div>
      </Modal>

      {contextHolder}
    </>
  );
};

export default Pricingcard;
