import React from "react";
import { Modal } from "antd";
import './../style/modals/_freeTrailModal.scss'
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import StripePayment from "../utils/payment/StripePayment";
import { useCompaign } from "../context/CompaignProvider";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const FreeTrailModal = ({ togglePopup, setTogglePopup }) => {
    const { profile } = useCompaign();
    const navigate = useNavigate();

    const handleCheckout = async () => {
        const stripe = await stripePromise;

        const data = {
            plan_name: `Free Trial`,
        };

        const response = await StripePayment(data);

        if (response.success) {
            const result = await stripe.redirectToCheckout({
                sessionId: response.session,
            });

            if (result.error) {
                console.error(result.error.message);
            } else {
                navigate("/settings?tab=subscription");
            }
        }
    };

    return (
        <>
            {profile.firstname && !profile.trial_used &&
                <Modal
                    open={togglePopup}
                    onOk={() => setTogglePopup(false)}
                    onCancel={() => setTogglePopup(false)}
                    className="free-trail-banner"
                    centered={true}
                >
                    <div className="free_trail">
                        {/* Banner */}
                        {/* <div className="banner">
                        <h2>Dueces AI - Bulk Mails Sender in One Go!</h2>
                    </div> */}

                        {/* Free trail Box */}
                        {/* <div className="content-box"> */}
                        {/* <button onClick={handleCheckout}>Start 7 days free trial</button> */}

                        <div className="shade" />
                        <div className="shade2" />

                        <button className="maininner-new" onClick={handleCheckout}>
                            <div className="inner">
                                <span>Start 7 Day Free Trial</span>
                            </div>
                        </button>

                        <div className="or-section">
                            <div className="bar" />
                            <span>OR</span>
                            <div className="bar" />
                        </div>

                        <button className="maininner-new" onClick={() => {
                            navigate('/subscription');
                            setTogglePopup(false)
                        }}>
                            <div className="inner">
                                <span>Subscribe a Plan</span>
                            </div>
                        </button>
                        {/* <Link to="/subscription" onClick={() => togglePopup(false)}>Subscribe a plan</Link> */}
                        {/* </div> */}
                    </div>
                </Modal>
            }
        </>
    );
};

export default FreeTrailModal;
